
import { defineComponent, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { setLogin } from "@/core/helpers/user";
import { clearError } from "@/core/helpers/Error";
import NotifService from "@/core/services/NotifService";
import useError from "@/core/composable/useError";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isError = ref(false);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string()
        .required("No password provided.")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "passwordRequired"
        )
        .label("Password"),
    });

    const resultLoginUser = computed(() => {
      return store.getters.resultLoginUser;
    });
    const { getError } = useError();
    //Form submit function
    const onSubmitLogin = async (values) => {
      await setLogin(values);
      isError.value = false;
      if (
        typeof resultLoginUser.value.accessToken !== "undefined" &&
        window.localStorage.getItem("token") !== null
      ) {
        router.push({ name: "home" });
        clearError();
      } else {
        isError.value = true;
        NotifService.displayErrorAlert(getError.value.message);
      }
    };

    return {
      onSubmitLogin,
      login,
      isError,
      resultLoginUser,
    };
  },
});
